/* .episodeContainer {
    width: 80%;
} */

.episodeCard {
    padding: 2vw;
    background-color: #001133;
}

.recentHeading {
    text-align: center;
    color: #FAF3DD;
}

.pagination {
    margin: auto;
}